.headerWrapper {
    display: flex;
    flex-direction: column;
    margin: 30px 0 0 30px;
}

.headerTitle {
    font-family: 'Roboto';
    color: #070000;
    font-size: 22px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.headerList {
    font-family: 'Roboto';
    display: flex;
    flex-direction: row;
    margin: 10px 0 0 0;
}

.headerList li {
    font-family: 'Roboto';
    color: #070000;
    list-style: none;
    padding-right: 5px;
    font-size: 16px;
    cursor: pointer;
}

.headerList li:last-child {
    list-style: none;
    padding-right: 5px;
    color: blue;
    cursor: inherit;
}

* {
    font-family: 'Roboto';
}
