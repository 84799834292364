.cardBlock {
    margin-top: 20px;
}

.cardBlockInputs {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
    margin: 20px auto;
    max-width: 400px;

}

.cardMainBlock {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center
}

.cardMainItems {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px;

    justify-content: center
}

.cardMainItemsScore {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px;
    align-items: center;
    justify-content: center
}

.cardBlockTitle {
    font-size: 18px;
    text-align: start;
    padding: 10px 0 10px 15px;
}

.itemWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px ;
    position: relative;
}
.addOneMore{
    margin: 20px;
    padding: 20px;
}

.itemNameInputBlock {
    display: flex;
    flex-direction: row;
}

.itemInput {
    width: 20px;
    height: 20px;
}

.imageWrapper{
    position: relative;
    min-width: 220px;
    max-width: 220px;
    min-height: 200px;
    max-height: 200px;
    margin: 10px;
}

.itemImg {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    border: 2px solid #dbdbdb;
    min-width: 220px;
    max-width: 220px;
    min-height: 200px;
    max-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0.4em 0.4em 5px rgba(122, 122, 122, 0.2);
    padding: 20px;
    margin: 20px;

    opacity: 1;
    transition: .5s ease;
    backface-visibility: hidden;
}

.itemImgAddOneMore {
    min-width: 220px;
    max-width: 220px;
    min-height: 200px;
    max-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;


}

.container {
    position: relative;
}
.help-text{
    color: #bababa;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);

    background-image: url("https://www.freeiconspng.com/thumbs/add-icon-png/add-icon--endless-icons-21.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 220px;
    height: 200px;
    cursor: pointer;
}

.container:hover .itemImg {
    opacity: 0.2;
}

.container:hover .middle {
    opacity: 1;
}



.itemTitle {
    font-size: 18px;
    font-weight: 400;
}

.cardBlockSave {
    margin: 20px;
    display: flex;
    justify-content: flex-end;
}

.cardMainNoData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 600px;
    height: 50vh;

}

.noDataFirstDesc {
    color: #070000;
    font-size: 26px;
}

.noDataSecondDesc {
    color: #070000;
    font-size: 26px;
}

.topPlaceTrue{
    position: absolute;
    top: 20px;
    right: 20px;
    width: 0;
    height: 0;
    border-top: 50px solid #15fd00;
    border-left: 50px solid transparent;
}

.topPlaceFalse{
    position: absolute;
    top: 20px;
    right: 20px;
    width: 0;
    height: 0;
    border-top: 50px solid #f60101;
    border-left: 50px solid transparent;
}

.data_no_loaded{
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;

}


.tournament {
    position: relative;
    min-height: 300px;
    padding: 20px;

}
.tournament__grid {
    font-size: 0;
    line-height: 0;
    display: flex;
    align-items: stretch;
    min-height: 400px;

}
.tournament__round {
    position: relative;
    flex: 1 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.tournament__match {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1 0;
    align-items: center;
    min-height: 100px;
}

.tournament__match:first-child {
    margin: 0 !important;
}
.tournament__round--first-round .tournament__match {
    padding-left: 0;
}
.tournament__round--winner .tournament__match {
    padding-right: 0;
    height: 32px;
}
.tournament__match:after {
    content: '';
    position: absolute;
    right: 0;
    width: 2px;
    background: rgba(0, 0, 0, .9);
    top: 25%;
    bottom: 25%;
}
.tournament__round--winner .tournament__match:after, .tournament__round--end-point .tournament__match:after {
    display: none;
}
.tournament__match__team {

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    font-weight: bold;
    transition: color 0.3s ease;
    color: #090000;
    text-decoration: none;
    box-sizing: border-box;
    border: 1px solid #000;

    position: relative;

    height: 36px;
    line-height: 32px;
    width: 170px;
    margin: auto;
}

.tournament__match__team_success{
    border: 2px solid #06e253 !important;
    transition: color 0.3s ease;
}

.tournament__match__team_lose{
    border: 2px solid #d71027 !important;
    transition: color 0.3s ease;
}



.tournament__match__team img{
    margin-right: 10px;
    width: 20px;
    height: 20px;
}


.tournament__match__team:before, .tournament__match__team:after {
    content: '';
    position: absolute;
    top: 50%;
    width: 999px;
    height: 2px;
    margin-top: -1px;
    background: rgba(0, 0, 0, .9);
}
.tournament__match__team:after {
    left: 100%;
}
.tournament__round:last-child .tournament__match__team:after, .tournament__round--end-point .tournament__match__team:after {
    display: none;
}
.tournament__match__team:before {
    right: 100%;
}
.tournament__round:first-child .tournament__match__team:before {
    display: none;
}


/*for Etid*/

.tournament__match__teamE {


    font-size: 14px;
    font-weight: bold;
    transition: color 0.3s ease;
    color: #090000;
    text-decoration: none;
    box-sizing: border-box;


    position: relative;

    height: 36px;

    width: 180px;
    margin: auto;
}
.tournament__itemContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.menuItemImg {
    margin-right: 10px;
    width: 20px;
    height: 20px;
}



.tournament__match__teamE:before, .tournament__match__teamE:after {
    content: '';
    position: absolute;
    top: 50%;
    width: 999px;
    height: 2px;
    margin-top: -1px;
    background: rgba(0, 0, 0, .9);
}
.tournament__match__teamE:after {
    left: 100%;
}
.tournament__round:last-child .tournament__match__teamE:after, .tournament__round--end-point .tournament__match__teamE:after {
    display: none;
}
.tournament__match__teamE:before {
    right: 100%;
}
.tournament__round:first-child .tournament__match__teamE:before {
    display: none;
}


@media (max-width: 550px){
    .itemNameInputBlock {
        display: flex;
        flex-direction: column;
    }
}
