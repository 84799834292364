

.avatar{
    margin: 0 auto;
    width: 140px;
    height: 140px;
}
.wrap {
    flex-direction: row;
    display: flex;
    align-items: center;
    padding: 10px;
}
.wrapAvatar {
    align-items: center;
    flex-direction: row;
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
}
.firstBlockInfo{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.infoWrapperKey{
    display: flex;
    flex-direction: column;
}
.infoWrapperValue{

}
.blanceWrappe{
    margin: 0 auto;
}
.balanceBlock{
    width: 200px;
    min-height: 100px;
    border-radius: 7px;
    /*background: linear-gradient(to top left, #0000ff 0%, #3399ff 63%);*/
    border: 1px solid #000000;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px 10px 10px 20px;


    /*-webkit-box-shadow: 27px 22px 15px -5px rgba(34, 60, 80, 0.32);*/
    /*-moz-box-shadow: 27px 22px 15px -5px rgba(34, 60, 80, 0.32);*/
    /*box-shadow: 27px 22px 15px -5px rgba(34, 60, 80, 0.32);*/
}
.balanceTextFirst{
    color: #070707;
    font-size: 18px;
    font-weight: 600;
}
.balanceTextSecond{
    margin-top: 10px;
    color: #171717;
    font-size: 18px;
    font-weight: 600;
}
.balanceTextThird{
    color: #000000;
    font-size: 12px;
    font-weight: 600;
}
.wrapInner {
    flex-direction: row;
    display: flex;
}
.label {
    width: 140px;
    font-size: 18px;
    font-family: Roboto;
    font-weight: bold;
}
.subtitle {
    font-size: 18px;
    font-family: 'Roboto';
    font-weight: bold;
    padding: 10px
}
.text{
    font-size: 18px;
    font-family: 'Roboto';
}
.panel{
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #5664d21c;
}
.panelInCareington {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #79b3ff1c;
    flex-direction: row;
    display: flex;
    align-items: center;
}
.statingWrap{

}
.stateItem{
    border-bottom: 1px solid black;
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.stateItemAva{
    width: 30px;
    height: 30px;

}
.stateItemName{
    flex: 1;
}
.stateItemCount{

}

@media (max-width: 786px) {
    .wrapAvatar {
        flex-direction: column;
    }
    .blanceWrappe{
        margin-top: 30px;
    }
    .avatar{
        margin-bottom: 20px;
    }
}

@media (max-width: 415px) {
    .wrap{
        display: flex;
        flex-direction: column;
    }
    .text{
        margin-left: 10px;
    }
}
